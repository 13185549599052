import * as forge from 'node-forge';

export const encrypt = (string) => {
    try {
        const key = forge.pkcs5.pbkdf2(process.env.GATSBY_PASSWORD, 'salt', 1, 16);
        const cipher = forge.cipher.createCipher(process.env.GATSBY_ALGORITHM, key);
        cipher.start({ iv: process.env.GATSBY_IV });
        cipher.update(forge.util.createBuffer(string, 'utf8'));
        cipher.finish();
        const encrypted = forge.util.encode64(cipher.output.getBytes());
        return encrypted;
    } catch (e) {
        console.log('error al codificar string');
        return '';
    }
}

export const decrypt = (string) => {
    try {
        const key = forge.pkcs5.pbkdf2(process.env.GATSBY_PASSWORD, 'salt', 1, 16);
        const decipher = forge.cipher.createDecipher(process.env.GATSBY_ALGORITHM, key);
        decipher.start({ iv: process.env.GATSBY_IV });
        const cipher = forge.util.decode64(string);
        const buffer = forge.util.createBuffer(cipher);
        decipher.update(buffer);
        decipher.finish();
        const bytes = decipher.output.getBytes();
        const decrypted = forge.util.decodeUtf8(bytes);
        return decrypted;
    } catch (e) {
        console.log('error al decodificar string');
        return '';
    }
}