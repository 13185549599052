import React, { useEffect } from "react";
import "../components/templates/HeaderComponents/header.css";
import { navigate } from "gatsby";
import clienteAxios from "../Utils/axios";
import Spinner from "../components/templates/Spinner";
import { logoutClaveUnica } from '../Utils/LogoutClaveUnica';
import { decrypt } from "../Utils/decrypt";
const { clean } = require("../Utils/ValidateRut");

const PagePrueba = ({ location }) => {

  const ConsultaClaveUnica = async (state, code) => {
    try {
      const dataForm = new FormData()
      dataForm.append("state", state)
      dataForm.append("code", code)

      const respuesta = await clienteAxios.post("claveunica/", dataForm)

      // let nombres = respuesta.data.nombres[0] + " " + respuesta.data.nombres[1]
      let nombres = respuesta.data.nombres

      let apellido_p = respuesta.data.apellido_p
      let apellido_m = ""

      if (respuesta.data.apellido_m.length > 1) {
        apellido_m = respuesta.data.apellido_m
      }

      if (respuesta.status === 200) {
        const dataFormToken = new FormData()
        dataFormToken.append("run", respuesta.data.run)
        dataFormToken.append("dv", respuesta.data.dv)
        dataFormToken.append("nombres", nombres)
        dataFormToken.append("apellido_p", apellido_p)
        dataFormToken.append("apellido_m", apellido_m)
        dataFormToken.append("ingreso_clave_unica", true)

        const respuestatoken = await clienteAxios.post(
          "AuthUserMiSuper/",
          dataFormToken
        )

        localStorage.setItem("token", respuestatoken.data.authuser)
        localStorage.setItem("refsh", respuestatoken.data.refresh)
        localStorage.setItem("rut", respuesta.data.run)
        localStorage.setItem("dv", respuesta.data.dv)
        localStorage.setItem("tipologin", "claveunica")

        if (respuestatoken.data.rfc.requiere_actualizar === true) {
          localStorage.setItem("requiere_actualizar", true)
          localStorage.setItem("logueado", true)
          navigate("/inicio/editar-perfil/")
        } else {
          localStorage.setItem("requiere_actualizar", false)
          localStorage.setItem("logueado", true)
          navigate("/inicio/")
        }
      }
    } catch (error) {
      console.log(error)
      logoutClaveUnica()
      const respuesta = await clienteAxios.get("claveunica/url/")

      setTimeout(() => {
        window.location.href = respuesta.data.url
      }, 5000)
    }
  }

  const getCookie = (cname) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  const ValidarRedireccion = async () => {
    const uid = getCookie('uid');
    if (uid) {
      const id = decrypt(uid);
      const run = clean(id.slice(0, -1));
      const dv = id.charAt(id.length - 1);

      const redis = await clienteAxios.get(process.env.GATSBY_REDIS + id);

      if (redis.status === 200 && redis.data.detail) {
        const v = JSON.parse(redis.data.detail);
        const folio = v.folio;
        const ingreso = v.ingreso;

        const dataFormToken = new FormData();
        dataFormToken.append("run", run);
        dataFormToken.append("dv", dv);
        dataFormToken.append("ingreso_clave_unica", false);

        const respuestatoken = await clienteAxios.post("AuthUserMiSuper/", dataFormToken);

        if (respuestatoken.status === 200) {
          localStorage.setItem("token", respuestatoken.data.authuser);
          localStorage.setItem("refsh", respuestatoken.data.refresh);
          localStorage.setItem("rut", run);
          localStorage.setItem("dv", dv);
          localStorage.setItem("tipologin", "tipodoc");
          localStorage.setItem("requiere_actualizar", respuestatoken.data.rfc.requiere_actualizar);
          localStorage.setItem("logueado", true);

          const datalist = new FormData();
          datalist.append('run', run);
          datalist.append('dv', dv);

          const respuestalista = await clienteAxios.post("ReclamosTramitesF/?s_folio=" + folio + "&s_fecha=" + ingreso, datalist, {
            headers: {
              Authorization: "Bearer " + respuestatoken.data.authuser,
              accept: "application/json",
            },
          });
          if (respuestalista.status === 200) {
            localStorage.setItem("lista", JSON.stringify(respuestalista.data.reclamo_prestador));
            navigate("/inicio/tramites/detalle/");
          }
        }
      }
    }
  }

  useEffect(() => {
    var Parametros = new URLSearchParams(location.search)
    const code = Parametros.get("code")
    const state = Parametros.get("state")
    if (state && code) {
      ConsultaClaveUnica(state, code)
    } else {
      ValidarRedireccion()
    }
  }, [location])// eslint-disable-line

  return (
    <>
      <Spinner />
    </>
  )
}

export default PagePrueba